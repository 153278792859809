@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';

@mixin apply-zonar-theme($theme){
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);

}

.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme{
  @include  apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}
